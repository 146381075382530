// ANSIA
import img0_1 from "./assets/images/seasons/2/ANSIA/1.jpg"
import img0_2 from "./assets/images/seasons/2/ANSIA/2.jpg"
import img0_3 from "./assets/images/seasons/2/ANSIA/3.jpg"
import img0_4 from "./assets/images/seasons/2/ANSIA/4.jpg"
import img0_5 from "./assets/images/seasons/2/ANSIA/5.jpg"
import img0_6 from "./assets/images/seasons/2/ANSIA/6.jpg"
import img0_7 from "./assets/images/seasons/2/ANSIA/7.jpg"
import img0_8 from "./assets/images/seasons/2/ANSIA/8.jpg"
import img0_9 from "./assets/images/seasons/2/ANSIA/9.jpg"
import img0_10 from "./assets/images/seasons/2/ANSIA/10.jpg"
// FIGHTER
import img1_1 from "./assets/images/seasons/2/FIGHTER/1.jpg"
import img1_2 from "./assets/images/seasons/2/FIGHTER/2.jpg"
import img1_3 from "./assets/images/seasons/2/FIGHTER/3.jpg"
import img1_4 from "./assets/images/seasons/2/FIGHTER/4.jpg"
import img1_5 from "./assets/images/seasons/2/FIGHTER/5.jpg"
import img1_6 from "./assets/images/seasons/2/FIGHTER/6.jpg"
import img1_7 from "./assets/images/seasons/2/FIGHTER/7.jpg"
import img1_8 from "./assets/images/seasons/2/FIGHTER/8.jpg"
import img1_9 from "./assets/images/seasons/2/FIGHTER/9.jpg"
import img1_10 from "./assets/images/seasons/2/FIGHTER/10.jpg"
// MOSTRI
import img2_1 from "./assets/images/seasons/2/MOSTRI/1.jpg"
import img2_2 from "./assets/images/seasons/2/MOSTRI/2.jpg"
import img2_3 from "./assets/images/seasons/2/MOSTRI/3.jpg"
import img2_4 from "./assets/images/seasons/2/MOSTRI/4.jpg"
import img2_5 from "./assets/images/seasons/2/MOSTRI/5.jpg"
import img2_6 from "./assets/images/seasons/2/MOSTRI/6.jpg"
import img2_7 from "./assets/images/seasons/2/MOSTRI/7.jpg"
import img2_8 from "./assets/images/seasons/2/MOSTRI/8.jpg"
import img2_9 from "./assets/images/seasons/2/MOSTRI/9.jpg"
import img2_10 from "./assets/images/seasons/2/MOSTRI/10.jpg"
// NERD
import img3_1 from "./assets/images/seasons/2/NERD/1.jpg"
import img3_2 from "./assets/images/seasons/2/NERD/2.jpg"
import img3_3 from "./assets/images/seasons/2/NERD/3.jpg"
import img3_4 from "./assets/images/seasons/2/NERD/4.jpg"
import img3_5 from "./assets/images/seasons/2/NERD/5.jpg"
import img3_6 from "./assets/images/seasons/2/NERD/6.jpg"
import img3_7 from "./assets/images/seasons/2/NERD/7.jpg"
import img3_8 from "./assets/images/seasons/2/NERD/8.jpg"
import img3_9 from "./assets/images/seasons/2/NERD/9.jpg"
import img3_10 from "./assets/images/seasons/2/NERD/10.jpg"
// NON A CASA
import img4_1 from "./assets/images/seasons/2/NON_A_CASA/1.jpg"
import img4_2 from "./assets/images/seasons/2/NON_A_CASA/2.jpg"
import img4_3 from "./assets/images/seasons/2/NON_A_CASA/3.jpg"
import img4_4 from "./assets/images/seasons/2/NON_A_CASA/4.jpg"
import img4_5 from "./assets/images/seasons/2/NON_A_CASA/5.jpg"
import img4_6 from "./assets/images/seasons/2/NON_A_CASA/6.jpg"
import img4_7 from "./assets/images/seasons/2/NON_A_CASA/7.jpg"
import img4_8 from "./assets/images/seasons/2/NON_A_CASA/8.jpg"
import img4_9 from "./assets/images/seasons/2/NON_A_CASA/9.jpg"
import img4_10 from "./assets/images/seasons/2/NON_A_CASA/10.jpg"
// LIVE
import img5_1 from "./assets/images/seasons/2/LIVE/1.jpg"
import img5_2 from "./assets/images/seasons/2/LIVE/2.jpg"
import img5_3 from "./assets/images/seasons/2/LIVE/3.jpg"
import img5_4 from "./assets/images/seasons/2/LIVE/4.jpg"
import img5_5 from "./assets/images/seasons/2/LIVE/5.jpg"
import img5_6 from "./assets/images/seasons/2/LIVE/6.jpg"
import img5_7 from "./assets/images/seasons/2/LIVE/7.jpg"
import img5_8 from "./assets/images/seasons/2/LIVE/8.jpg"
import img5_9 from "./assets/images/seasons/2/LIVE/9.jpg"
import img5_10 from "./assets/images/seasons/2/LIVE/10.jpg"
/* 
  List of default image URLs (mostly used for debugging)
*/
export const IMAGE_LIST = [
  [
    img0_1,
    img0_2,
    img0_3,
    img0_4,
    img0_5,
    img0_6,
    img0_7,
    img0_8,
    img0_9,
    img0_10,
  ],
  [
    img1_1,
    img1_2,
    img1_3,
    img1_4,
    img1_5,
    img1_6,
    img1_7,
    img1_8,
    img1_9,
    img1_10,
  ],
  [
    img2_1,
    img2_2,
    img2_3,
    img2_4,
    img2_5,
    img2_6,
    img2_7,
    img2_8,
    img2_9,
    img2_10,
  ],
  [
    img3_1,
    img3_2,
    img3_3,
    img3_4,
    img3_5,
    img3_6,
    img3_7,
    img3_8,
    img3_9,
    img3_10,
  ],
  [
    img4_1,
    img4_2,
    img4_3,
    img4_4,
    img4_5,
    img4_6,
    img4_7,
    img4_8,
    img4_9,
    img4_10,
  ],
  [
    img5_1,
    img5_2,
    img5_3,
    img5_4,
    img5_5,
    img5_6,
    img5_7,
    img5_8,
    img5_9,
    img5_10,
  ]
]

/* 
  Constants for copy to clipboard feedback
*/
export const copySuccessText = 'Copied!'
export const copyErrorText = 'Error copying!'
export const copyStatusResetTimer = 1000
